import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import PropTypes from "prop-types";
import LocalizedLink from "components/localizedLink";
import YemeniMap from "../components/data/map";
import { FaRegCalendarAlt, FaAngleLeft } from "react-icons/fa";
import { remove, slice, head } from "ramda";
import { colors } from "style/theme";
import { LocaleContext } from "context/locale-context";
import { MdLocationOn } from "react-icons/md";
import useTranslations from "components/hooks/useTranslations";
import { rhythm } from "utils/typography";
import { mq, formatDate } from "utils/helper";
import observations from "../../data/observations.json";

const Link = styled.a({
  color: "#000000",
  textDecoration: "none",
  textTransform: "capitalize",
  transition: "0.2s",
  ":hover": {
    color: colors.primary,
  },
});

function ObservationsList({ nodes }) {
  const media = nodes.media;
  //const list = remove(0, 1, nodesList);
  const tr = useTranslations();
  return (
    <div
      css={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridRowGap: "2rem",
      }}
    >
      {media.map((node) => (
        <div key={node.id}>
          <Link
            href={`https://yemeniarchive.org/en/database/unit/${node.id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {tr(node.platform)}
          </Link>
        </div>
      ))}
    </div>
  );
}

function IncidentTemplate({ data }) {
  const {
    code,
    weapons,
    location,
    type,
    name_en,
    name_ar,
    latitude,
    longitude,
    date,
    title_ar,
    title_en,
    summary_ar,
    summary_en,
  } = data.incidentsJson;

  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const tr = useTranslations();

  const nodes = head(
    observations.filter((observation) => observation.code == code)
  );

  return (
    <Layout>
      <div
        css={{
          backgroundColor: colors.bg,
          direction: isRTL ? "rtl" : "ltr",
        }}
      >
        <div
          css={{
            maxWidth: "1200px",
            margin: "auto",
            paddingTop: rhythm(2),
            [mq[1]]: {
              paddingTop: rhythm(1),
            },
          }}
        >
          <LocalizedLink
            to={"data"}
            css={{
              position: "relative",
              marginLeft: rhythm(0.5),
              [mq[1]]: {
                marginLeft: rhythm(1),
              },
            }}
          >
            <span
              css={{
                paddingRight: rhythm(0.4),
                position: "absolute",
                top: rhythm(0.1),
                left: rhythm(-0.8),
              }}
            >
              <FaAngleLeft />
            </span>
            <span css={{ fontWeight: "bold" }}> {tr("Back to Dataset")}</span>
          </LocalizedLink>
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(20px, 1fr))",
              gridColumnGap: rhythm(1),
              paddingTop: rhythm(2),
              [mq[0]]: {
                display: "block",
                paddingTop: rhythm(1),
              },
            }}
          >
            <div
              css={{
                padding: rhythm(1),
                backgroundColor: colors.light,
                marginBottom: rhythm(2),
              }}
            >
              <small>
                {tr("Incident")} : {code}
              </small>
              <h3
                css={{
                  marginTop: rhythm(1),
                }}
              >
                {isRTL ? title_ar : title_en}
              </h3>
              <div
                css={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "75%",
                  [mq[1]]: {
                    display: "block",
                  },
                }}
              >
                <small
                  css={{
                    fontWeight: "bold",
                  }}
                >
                  <FaRegCalendarAlt />
                  <span
                    css={{ paddingRight: "0.35rem", paddingLeft: "0.35rem" }}
                  >
                    {formatDate(date, locale)}
                  </span>
                </small>

                <small>
                  <MdLocationOn />
                  <span
                    css={{ paddingRight: "0.35rem", paddingLeft: "0.35rem" }}
                  >
                    {location}
                  </span>{" "}
                  <span title="Longitude:Latitude">
                    {longitude} || {latitude}
                  </span>
                </small>
              </div>
              <div
                css={{
                  width: "97%",
                  margin: "auto",
                  marginTop: rhythm(1.3),
                  borderBottom: `1px solid ${colors.primary}`,
                }}
              ></div>
              <div
                css={{
                  marginTop: rhythm(0.5),
                }}
              >
                <h5>{tr("Summary")}:</h5>
                <p>{isRTL ? summary_ar : summary_en}</p>
              </div>
              <div>
                <h5>{tr("Weapons Used")}:</h5>
                <p>{tr("Air Strick")}</p>
              </div>
              <div
                css={{
                  width: "97%",
                  margin: "auto",
                  marginTop: rhythm(1.3),
                  borderBottom: `1px solid ${colors.primary}`,
                }}
              ></div>
              <div
                css={{
                  marginTop: rhythm(0.5),
                }}
              >
                <h5>{tr("Observations")}:</h5>
                <ObservationsList nodes={nodes} />
              </div>
            </div>
            <div
              css={{
                position: "sticky",
                top: "12vh",
                height: "100vh",
                boxSizing: "border-box",
              }}
            >
              <YemeniMap
                units={[data.incidentsJson]}
                viewport={{
                  lat: data.incidentsJson.latitude,
                  lng: data.incidentsJson.longitude,
                  zoom: 7,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

IncidentTemplate.propTypes = {
  location: PropTypes.obj,
};

export default IncidentTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    incidentsJson(id: { eq: $id }) {
      code
      location
      name_en
      name_ar
      latitude
      longitude
      date
      title_ar
      title_en
      summary_ar
      summary_en
    }
  }
`;
